<template>
  <div class="py-1 px-2 rounded border" v-bind:style="{ backgroundColor: color }">
    {{ this.params.value.value }}
  </div>
</template>
<script>
export default {
  data() {
    return {
      color: null,
    };
  },
  beforeMount() {
    this.color = this.params.color || 'white';
  },
};
</script>
